import React, { useState } from 'react';
import { MainContent } from '../../App';

import logo from '../../assets/logo/LogoWhiteTrans.png';
import Contact from '../main/Contact';
import Gigs from '../main/Gigs';
import Trallskruv from '../main/Trallskruv';

import { HeaderWrapper, Logo, Menu, MenuButton } from './Menu.styles';

const Header = ({ content, setContent, setAddOn }: any) => {
  const [active, setActive] = useState(MainContent.gigs);
  return (
    <HeaderWrapper>
      <Logo
        src={'./icons/LogoWhiteTrans.png'}
        alt="logo"
        onClick={() => setAddOn(true)}
      />

      <Menu>
        <MenuButton
          {...{
            onClick: () => {
              setActive(MainContent.trallskruv);
              setContent(<Trallskruv />);
            },
            isActive: active === MainContent.trallskruv,
          }}
        >
          {MainContent.trallskruv}
        </MenuButton>
        <MenuButton
          {...{
            onClick: () => {
              setActive(MainContent.gigs);
              setContent(<Gigs />);
            },
            isActive: active === MainContent.gigs,
          }}
        >
          {MainContent.gigs}
        </MenuButton>
        <MenuButton
          {...{
            onClick: () => {
              setActive(MainContent.contact);
              setContent(<Contact />);
            },
            isActive: active === MainContent.contact,
          }}
        >
          {MainContent.contact}
        </MenuButton>
      </Menu>
    </HeaderWrapper>
  );
};

export default Header;
