import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  background: #c78a8a51;
  padding: 1rem;
  height: calc(100% - 1.5rem);
  border-radius: 1rem;
  box-shadow: -5px -5px 5px black;
  max-width: 400px;
  margin: auto;
  h2 {
    text-align: center;
  }
`;

const GigWrapper = styled.table`
  th {
    background-color: #fff0f066;
    border-bottom: 2px double white;
    text-align: left;
    font-size: 1rem;
    padding: 0.3rem 0.7rem;
  }
`;
const Td = styled.td<{ borderRight: boolean }>`
  border-bottom: 1px solid black;
  padding: 0.5rem;
  font-size: 0.7rem;
  border-right: ${({ borderRight }) =>
    borderRight && '1px solid white'};
`;
const Gigs = () => {
  const gigs = [
    {
      date: '2024-10-05',
      city: 'Nyköping',
      place: 'Magasinet',
    },
    {
      date: '2024-10-11',
      city: 'Borlänge',
      place: 'House of punk',
    },
    {
      date: '2024-10-25',
      city: 'Umeå',
      place: 'Umapunk',
    },
    {
      date: '2024-10-26',
      city: 'Sundsvall',
      place: 'Pipeline',
    },
    {
      date: '2024-11-08',
      city: 'Sandviken',
      place: 'Alternativfesten',
    },
  ];
  return (
    <Wrapper>
      <h2>Gigs</h2>
      <GigWrapper>
        <tbody>
          <tr>
            <th>datum</th>
            <th>Stad</th>
            <th>Ställe</th>
          </tr>
          {gigs.map((gig, index) => (
            <tr key={index}>
              <Td {...{ borderRight: true }}>{gig.date}</Td>
              <Td {...{ borderRight: true }}>{gig.city}</Td>
              <Td {...{ borderRight: false }}>{gig.place}</Td>
            </tr>
          ))}
        </tbody>
      </GigWrapper>
      <img src="https://trallskruv.com/logo192.png" alt="En logga" />
    </Wrapper>
  );
};

export default Gigs;
