import React, { useState } from 'react';
import './App.css';
import styled from 'styled-components';
import Header from './components/header';
import Main from './components/main';
import Footer from './components/footer';
import Gigs from './components/main/Gigs';
import AddOn from './components/main/AddOn';
import { newCd } from './components/main/Trallskruv';

const Wrapper = styled.div<{ height: number }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  background: url('img/background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: ${({ height }) => height}px;
`;

export enum MainContent {
  news = 'Nyheter',
  music = 'Musiken',
  trallskruv = 'Trallskruv',
  gigs = 'Gig',
  contact = 'Kontakt',
}
function App() {
  const [content, setContent] = useState<JSX.Element>(<Gigs />);
  const [addOn, setAddOn] = useState(false);
  const [addOnContent] = useState(
    <AddOn {...{ setAddOn, persona: newCd }} />
  );

  return (
    <Wrapper height={window.innerHeight}>
      {addOn && addOnContent}
      <Header {...{ content, setContent, setAddOn }} />
      <Main {...{ content, setContent }} />
      <Footer />
    </Wrapper>
  );
}

export default App;
