import React from 'react';
import styled from 'styled-components';
const AddOnWrapper = styled.div`
  z-index: 9;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #00000070;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;
const Add = styled.div`
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  background-color: #a26664;
  border-radius: 0.5rem;
  margin: 1rem;
  box-shadow: 0px 0px 100px white, 0px 0px 100px white,
    0px 0px 100px white, 0px 0px 100px white, 0px 0px 100px white,
    0px 0px 100px white, 0px 0px 100px white;
`;

const Close = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  align-self: flex-end;
  border-radius: 20rem;
  padding: 0.5rem;
  background-color: #0000006b;
  border: 1px solid #dadada;
  z-index: 11;
  color: white;
  cursor: pointer;
  transition: 200ms;
  :hover {
    background-color: black;
  }
`;
const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(300px + 2rem);
`;
const Img = styled.img`
  width: calc(300px - 2rem);
  height: calc(300px - 2rem);
  cursor: pointer;
`;
const P = styled.p`
  align-self: center;
  font-size: 1.2rem;
  font-weight: 800;
  text-shadow: 0px 0px 20px #a26664, 0px 0px 20px #a26664,
    0px 0px 20px #a26664, 0px 0px 20px #a26664;
  line-height: 0rem;
  z-index: 11;
`;
const Text = styled.p`
  align-self: center;
  font-size: 0.7rem;
  text-shadow: 0px 0px 20px #d3d3d3, 0px 0px 20px #d3d3d3,
    0px 0px 20px #d3d3d3, 0px 0px 20px #d3d3d3, 0px 0px 20px #d3d3d3,
    0px 0px 20px #d3d3d3, 0px 0px 20px #d3d3d3, 0px 0px 20px #d3d3d3,
    0px 0px 20px #d3d3d3, 0px 0px 20px #d3d3d3, 0px 0px 20px #d3d3d3;
  line-height: 1rem;
  text-align: center;
  z-index: 11;
`;
const AddOn = ({ setAddOn, persona }: any) => {
  return (
    <AddOnWrapper>
      <Flexbox>
        <Close onClick={() => setAddOn(false)}>X</Close>
        <P>{persona.name}</P>
        <Add>
          <a
            href="https://open.spotify.com/album/6IIr4mhXiRtvbqnDVDRs4N?si=bEJDte7HRhSztPVv-iS_PA&fbclid=IwAR2lKzHPkh42QPbrMfx3Ma3mKwF76iz1XK7gIEZLh8U8ve1gM-RbYrtmK7o&nd=1"
            target="_blank"
            rel="noreferrer"
          >
            <Img src={`./img/${persona.imgSrc}`} />
          </a>
        </Add>

        {persona.desc.map((text: any) => (
          <Text>{text}</Text>
        ))}

        <Text>{persona.citat}</Text>
      </Flexbox>
    </AddOnWrapper>
  );
};

export default AddOn;
