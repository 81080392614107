import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 2rem);
  padding: 1rem;
  background: linear-gradient(black 10%, #ffffff20 90%, transparent);
`;

export const Logo = styled.img`
  width: 5rem;
  height: 5rem;
  cursor: pointer;
`;

export const Menu = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 1rem, 0.25rem;
  outline: none;
  border: 0;
  max-width: 600px;
  margin-top: 0.5rem;
`;
export const MenuButton = styled.button<{ isActive: boolean }>`
  background: transparent;
  color: #ffffff;
  opacity: 0.85;
  outline: none;
  border: 0;
  border-bottom: ${({ isActive }) => (isActive ? "1px solid white" : "none")};
  font-size: 1.3rem;
  padding: 0 1rem;
  cursor: pointer;
  &:hover {
    color: gray;
  }
`;
