import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 2rem);
  padding: 1rem;
  background: linear-gradient(
    transparent 10%,
    #ffffff20 20%,
    black 90%
  );
  position: fixed;
  bottom: 0;
`;

const Footer = () => {
  return (
    <Wrapper>
      <p>Trallskruv</p>
      <a
        href="https://open.spotify.com/artist/4XIg7phBxKa77OOkkdvGGg"
        target="_blank"
        rel="noreferrer"
      >
        <img
          style={{ height: 40 }}
          src="./icons/Spotify_Logo.png"
          alt="SpotifyLogo"
        />
      </a>
      <p>2024</p>
    </Wrapper>
  );
};

export default Footer;
