import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  background: #c78a8a51;
  padding: 1rem;
  height: calc(100% - 1.5rem);
  border-radius: 1rem;
  box-shadow: -5px -5px 5px black;
  max-width: 400px;
  margin: auto;
  a {
    text-decoration: none;
    color: #fbdddd;
    font-size: large;
    padding: 0.5rem;
    transition: 300ms;
    border-radius: 1rem;
    :hover {
      border-left: 2px solid #c78a8a71;
      padding-left: 2rem;
    }
  }
  h2 {
    text-align: center;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
const Contact = () => {
  return (
    <Wrapper>
      <h2>Kontakta oss</h2>
      <a
        href="https://www.facebook.com/trallskruvpunk/"
        target="_blank"
        rel="noreferrer"
      >
        <IconWrapper>
          <img src="./icons/facebook.png" alt="facebook" />
          <span>Facebook</span>
        </IconWrapper>
      </a>
      <a
        href="https://www.instagram.com/trallskruvpunk/"
        target="_blank"
        rel="noreferrer"
      >
        <IconWrapper>
          <img src="./icons/instagram.png" alt="instagram" />
          <span>Instagram</span>
        </IconWrapper>
      </a>
      <a
        href="https://trallskruv.bandcamp.com/"
        target="_blank"
        rel="noreferrer"
      >
        <IconWrapper>
          <img src=".icons/bandcamp.png" alt="bandcamp" />
          <span>Bandcamp</span>
        </IconWrapper>
      </a>
      <a href="mailto:trallskruvpunk@gmail.com">
        <IconWrapper>
          <img src="./icons/mail.png" alt="mail icon" />
          <span>Maila oss här</span>
        </IconWrapper>
      </a>
      <hr style={{ width: '100%', height: '1px' }} />
      <IconWrapper>
        <b>eller till:</b>
        <p>trallskruvpunk@gmail.com</p>
      </IconWrapper>
    </Wrapper>
  );
};

export default Contact;
