import React from "react";
import styled from "styled-components";

export enum NestedRoutesStrings {
  nyheter = "nyheter",
  gigs = "gigs",
  musicAndLyrics = "musik&texter",
  contact = "kontakt",
  trallskruv = "bandet",
}

const Wrapper = styled.main`
  padding: 2rem;
  padding-bottom: 8rem;
`;
// const Site = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   height: 100%;
//   justify-content: center;
//   align-items: center;
//   text-align: center;
//   background: linear-gradient(
//     rgba(255, 255, 255, 0.2),
//     rgba(255, 255, 255, 0) 75%,
//     rgba(255, 255, 255, 0.2)
//   );
//   line-height: 1rem;
//   border-radius: 1rem;
// `;

const Main = ({ content, setContent }: any) => {
  return <Wrapper>{content}</Wrapper>;
};

export default Main;
