import React, { useState } from 'react';
import styled from 'styled-components';

export const newCd = {
  name: 'Trallskruv',
  imgSrc: 'LogoWhiteTrans.png',
  instrument: '',
  desc: [''],
  citat: '',
};

export const Personas = {
  Praper: {
    name: 'Pråper',
    instrument: 'Sång',
    imgSrc: 'praper.jpeg',
    desc: [
      'Bandets eviga frontfigur med ett, enligt han själv, lagom stort ego. Uppväxt på gräddhyllan med platinasked i munnen söker han sig till pöbeln för att sprida sin propaganda.',
      'Ryktas söka medlemskap hos diverse ordnar, men får avslag pga sina radikala åsikter. Även att han nyligen kommit till insikt att hans bandmedlemmar också har känslor.',
    ],
    citat: '"Tala inte när jag avbryter"',
  },
  EmpaCoolBoy_90: {
    name: 'EmpaCoolBoy_90',
    instrument: 'Gitarr/Sång',
    imgSrc: 'empacoolboy_90.jpg',
    citat:
      '"Mitt enda problem är hur jag ska kunna spendera mina pengar"',
    desc: [
      'Inte en underbyxa torr när vår spralliga ungtupp till gitarrist, tillika fotomodell äntrar scenen. Kan ofta ses på stureplans hippaste nattklubbar tillsammans med kändiseliten.',
      'Ryktas ha vaskat mer skumpa än han har druckit.',
    ],
  },
  Farbror: {
    name: 'Farbror',
    instrument: 'Bas/Sång',
    imgSrc: 'farbror.jpg',
    desc: [
      'Vår bittra basist är en övervintrad punkare som prioriterar kir, pilsner och fest över boende, kläder och arbete.',
      'Ryktas att han umgås med Pråper och Empacoolboy_90 för pengarnas skull.',
    ],
    citat: '"Tänk positivt, det blir värre"',
  },
  Drangen: {
    name: 'EmpaCoolBoy_90',
    instrument: 'Gitarr',
    imgSrc: 'drangen.jpg',
    desc: [
      ' Vår D-taktberoende gitarrist är en lantis från de bördiga jordarna på Svartsjölandet. Mestadels skygg varelse som bara tycks existera i samband med kirkalas.',
      'Ryktas ha delat dike med Farbror vid flera tillfällen men detta är svårt att bekräfta då ingen av dom svarar på tilltal.',
    ],
    citat:
      '"Först ska jag göra ingenting alls. Sen tar jag dagen som den kommer."',
  },

  Svensson: {
    name: 'Svensson',
    instrument: 'Trummor',
    imgSrc: 'svensson.jpg',
    desc: [
      'Vår trofasta takthållare är en fantastisk trebarnsfar bosatt i familjevänliga Bålsta. Han är en expert på tidsplanering, då han hinner skjutsa barn mellan minidisco och fotbollsträning och ändå har tid över för sina bandkamrater.',
      'Ryktas att han inte bara är en god vän och trummis, utan även en jävel på falukorv i ugn.',
    ],
    citat:
      '"Nu är det dags att runda av. Det är en dag imorgon också"',
  },
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  background: #c78a8a51;
  padding: 1rem;
  height: 100%;
  overflow: auto;
  border-radius: 1rem;
  box-shadow: -5px -5px 5px black;
  max-width: 400px;
  margin: auto;
  h2 {
    text-align: center;
  }
`;
const PersonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 18rem;
  margin-top: 3rem;
  padding: 1rem;
  border-radius: 0.5rem;
  padding-bottom: 4rem;
  margin-bottom: 5rem;
`;

const Button = styled.button`
  color: #fde1e1;
  text-shadow: 0px 0px 10px black, 0px 0px 10px black,
    0px 0px 10px black;
  background-color: #c78a8a70;
  border: none;
  border-radius: 4px;
  padding: 0.2rem;
  text-align: left;
  transition: 300ms;
  margin: 0.1rem;
  cursor: pointer;
  :hover {
    background-color: #c78a8a80;
    padding-left: 1rem;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;
const BoldText = styled.p`
  font-weight: 900;
  line-height: 0.5px;
`;
const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 600px;
  padding: 1rem;
`;
const Img = styled.img`
  width: auto;
  max-width: 10rem;
  border-radius: 1rem;
`;

const DescText = styled.p`
  font-size: 0.7rem;
  font-style: 'italic';
  text-align: 'center';
`;
const Trallskruv = () => {
  const [persona, setPersona] = useState(Personas.Praper);
  return (
    <Wrapper>
      <h2>Bandet</h2>
      <Button onClick={() => setPersona(Personas.Praper)}>
        Pråper
      </Button>
      <Button onClick={() => setPersona(Personas.EmpaCoolBoy_90)}>
        EmpaCoolBoy_90
      </Button>
      <Button onClick={() => setPersona(Personas.Farbror)}>
        Farbror
      </Button>
      <Button onClick={() => setPersona(Personas.Drangen)}>
        Drängen
      </Button>
      <Button onClick={() => setPersona(Personas.Svensson)}>
        Svensson
      </Button>
      <PersonWrapper>
        {persona.imgSrc !== '' ? (
          <ImgWrapper>
            <Img
              src={`./img/${persona.imgSrc}`}
              alt="picture of a member"
            />
            {persona.desc.map((desc) => (
              <DescText>{desc}</DescText>
            ))}
            <DescText>{persona.citat}</DescText>
          </ImgWrapper>
        ) : (
          <p>hmm..kan något vara på g..</p>
        )}
        <TextWrapper>
          <div>
            <BoldText>Namn:</BoldText>
            <p>{persona.name}</p>
          </div>
          <div>
            <BoldText>Instrument:</BoldText>
            <p>{persona.instrument}</p>
          </div>
        </TextWrapper>
      </PersonWrapper>
    </Wrapper>
  );
};

export default Trallskruv;
